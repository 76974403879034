<template>
  <Loading v-if="isLoading" />
  <div v-else class="sliders-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("sliders.slider") }}</h1>
        <div
          class="d-flex align-items-center"
          v-if="globalPermission.sliders && globalPermission.sliders.store"
        >
          <router-link
            :to="{ name: 'add-slider' }"
            variant="primary"
            class="btn btn-primary btn-add"
          >
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("sliders.add_slide") }}
          </router-link>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'title'" class="text-nowrap">
            {{ $t("sliders.title") }}
          </span>
          <span
            v-else-if="props.column.label === 'button_text'"
            class="text-nowrap"
          >
            {{ $t("sliders.title_btn") }}
          </span>
          <span v-else-if="props.column.label === 'show'" class="text-nowrap">
            {{ $t("sliders.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span
            class="text-nowrap custom-toggle"
            v-if="props.column.field === 'is_active'"
          >
            <b-form-checkbox
              :disabled="
                globalPermission.sliders &&
                !globalPermission.sliders['edit-slider-data']
              "
              switch
              v-model="props.row.is_active"
              @change="(e) => handleslidersVisibility(e, props.row.id)"
              :value="1"
              :unchecked-value="0"
            />
          </span>

          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' && globalPermission.sliders
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    globalPermission.sliders &&
                    globalPermission.sliders['edit-slider-data']
                  "
                  :to="{ name: 'edit-slider', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.sliders &&
                    globalPermission.sliders['destroy-slider-data']
                  "
                  @click="deleteSliders(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";

export default {
  name: "SlidersList",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BButton,
    Loading,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    BForm,
  },
  data() {
    return {
      isLoading: true,
      pageLength: 10,
      searchTerm: "",
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "title",
          field: "title",
        },
        {
          label: "button_text",
          field: "button_text",
        },
        {
          label: "show",
          field: "is_active",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getSlidersList();
  },
  created() {
    if (
      this.globalPermission.sliders &&
      !(
        this.globalPermission.sliders["edit-slider-data"] ||
        this.globalPermission.sliders["destroy-slider-data"]
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "Actions"
      );
    }
  },
  methods: {
    async getSlidersList() {
      try {
        const response = (await this.$http.get("admin/sliders")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          el.index = index + 1;
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteSliders(slidersId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handledeletesliders(slidersId),
          "The slider has been successfully deleted"
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handledeletesliders(slidersId) {
      this.$http
        .delete(`admin/sliders/slider-data/${slidersId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getSlidersList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async handleslidersVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_active: checked,
        };
        const res = await this.$http.post(
          `admin/sliders/slider-data/${id}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
